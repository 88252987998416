import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import dayjs from 'dayjs'

import fr from './fr.json'
import { setDayjsLocale } from './plugins/dayjsConfig'

export const locales: {
  [key: string]: {
    [key: string]: string
  }
} = {
  fr,
}

const i18nInstance = i18n

i18nInstance.use(initReactI18next)
i18nInstance.init({
  react: {
    useSuspense: false,
  },
  debug: false,
  interpolation: {
    escapeValue: false,
    format: (value, format) => {
      if (format === 'uppercase') {
        return value.toUpperCase()
      }
      if (value instanceof Date) {
        return dayjs(value).format(format)
      }
      return value
    },
  },
})

i18nInstance.on('languageChanged', (lng) => {
  setDayjsLocale(lng)
})

export const ns = 'translation'
export const addBundle = (lang: string, labels: { [key: string]: string }) => {
  i18n.addResourceBundle(lang, ns, labels, true, true)
}

export type i18nLabel = {
  [key: string]: string
}

export type i18nLabels = {
  [key: string]: i18nLabel
}

export type i18nData = {
  lang: string
  labels: i18nLabels
}
export const initWithLangAndLabels = (
  lang: string,
  labels: { [key: string]: string }
): i18nData => {
  addBundle(lang, { ...locales?.[lang], ...labels })
  i18n.changeLanguage(lang)

  return {
    lang,
    labels: {
      [lang]: { ...labels },
    },
  }
}

export default i18nInstance
